import cx from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import CS from "metabase/css/core/index.css";
import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
  };

  render() {
    const { dark, height, width } = this.props;
    return (
      <svg
        className={cx(
          "Icon",
          { [CS.textBrand]: !dark },
          { [CS.textWhite]: dark },
        )}
        viewBox="0 0 170 62"
        width={width}
        height={height}
        fill="currentcolor"
        data-testid="main-logo"
      >
        <g clip-path="url(#clip0_142_20216)">
          <path d="M99.5517 23.0805V22.1683C99.559 20.2046 98.7935 18.3177 97.4221 16.9193C96.0508 15.521 94.185 14.7247 92.2318 14.7043H69.046V47.1035H92.2318C94.1859 47.083 96.0526 46.286 97.4241 44.8864C98.7956 43.4869 99.5605 41.5986 99.5517 39.634V23.0805ZM95.3666 38.683C95.3666 38.7383 95.3665 40.6236 94.4646 41.6133C93.5627 42.603 92.3803 42.8131 91.0989 42.8794C90.549 42.9126 86.9688 42.9236 86.4024 42.9181H73.2311V18.862H86.4024C86.9523 18.862 90.527 18.862 91.0989 18.9007C92.3803 18.9671 93.5352 19.1606 94.4646 20.1723C95.394 21.1841 95.3666 23.0474 95.3666 23.1027V38.6719V38.683Z" fill="#21418A"/>
          <path d="M134.825 23.0805V22.1683C134.833 20.2046 134.067 18.3177 132.696 16.9193C131.325 15.521 129.459 14.7247 127.506 14.7043H104.32V47.1035H127.506C129.46 47.083 131.326 46.286 132.698 44.8864C134.069 43.4869 134.834 41.5986 134.825 39.634V23.0805ZM130.64 38.683C130.64 38.7383 130.64 40.6236 129.738 41.6133C128.836 42.603 127.654 42.8131 126.373 42.8794C125.823 42.9126 122.243 42.9236 121.676 42.9181H108.477V18.862H121.676C122.226 18.862 125.801 18.862 126.373 18.9007C127.654 18.9671 128.809 19.1606 129.738 20.1723C130.668 21.1841 130.64 23.0474 130.64 23.1027V38.6719V38.683Z" fill="#21418A"/>
          <path d="M169.995 42.8905H147.964C147.909 42.8905 146.033 42.8905 145.049 41.9837C144.064 41.077 143.855 39.8883 143.789 38.6001C143.756 38.0472 143.745 34.4479 143.745 33.8784V14.6988H139.604V39.739C139.625 41.7026 140.417 43.5784 141.808 44.9571C143.199 46.3357 145.075 47.1053 147.029 47.0979H170L169.995 42.8905Z" fill="#21418A"/>
          <path d="M30.335 0.401169C24.3353 0.401169 18.4703 2.18977 13.4817 5.54085C8.49308 8.89193 4.60496 13.655 2.30897 19.2276C0.0129703 24.8002 -0.587831 30.9322 0.582657 36.8481C1.75315 42.764 4.64234 48.1981 8.88478 52.4632C13.1272 56.7283 18.5324 59.6329 24.4169 60.8096C30.3013 61.9864 36.4007 61.3824 41.9437 59.0742C47.4867 56.7659 52.2244 52.857 55.5577 47.8418C58.891 42.8265 60.6701 36.9302 60.6701 30.8984C60.6708 26.8932 59.8867 22.9272 58.3625 19.2268C56.8382 15.5263 54.6037 12.164 51.7867 9.33196C48.9697 6.49988 45.6253 4.25351 41.9446 2.72114C38.2638 1.18877 34.3189 0.400443 30.335 0.401169ZM44.8921 45.0302C41.2349 48.7013 36.2469 50.6143 30.0435 50.7139C27.4922 50.7977 24.9409 50.5467 22.4542 49.9674C23.928 48.353 26.7933 45.2292 27.6457 44.3004C31.9573 44.3004 36.7748 44.2009 40.41 40.5518C42.187 38.7626 43.3968 36.4842 43.8867 34.0043C44.3766 31.5243 44.1246 28.954 43.1626 26.618C42.2005 24.2819 40.5715 22.2848 38.4813 20.8788C36.3911 19.4728 33.9333 18.721 31.4184 18.7183H20.3314V39.4847L27.4807 32.17H34.916L19.5559 48.6958C18.83 48.3143 17.5157 47.59 16.5808 47.0372C15.4809 46.3239 14.5184 45.5886 14.062 45.2237V18.7404V12.3822H31.5119L32.2983 12.4209C35.9851 12.5836 39.5451 13.8216 42.5441 15.9837C45.543 18.1459 47.8512 21.1388 49.187 24.5973C50.5228 28.0558 50.8285 31.8305 50.0669 35.4607C49.3052 39.0909 47.5091 42.4198 44.8976 45.0412L44.8921 45.0302Z" fill="#F37021"/>
        </g>
        <defs>
          <clipPath id="clip0_142_20216">
            <rect width="170" height="61" fill="white" transform="translate(0 0.401123)"/>
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
